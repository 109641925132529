import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

Vue.config.productionTip = false

//引入全局样式
import "./assets/public.css"

//引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//二次封装 element message
import { message } from "./utils/resetMessage"
Vue.prototype.$message = message;

//模拟微信弹窗
import wxs from '@/utils/wxs.js'
Vue.use(wxs)

// url
import config from '@/utils/config'
Vue.prototype.$url = config.url;


import { Uploader } from 'vant';
import 'vant/lib/index.css';
Vue.use(Uploader);


new Vue({
  wxs,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
