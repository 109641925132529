<template>
  <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{

    }
  }
}
</script>

<style lang="less">
//修改elementUi Message
// .el-message{
//     // background-color: rgba(20, 45, 74, 0.3);;
//     top: 80px !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     min-width: 150px;
// }
</style>
