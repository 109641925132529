import Vue from "vue";
import VueRouter from "vue-router";

// Vue.use(VueRouter);

const routes = [
    //权限分配 -管理员管理
    {
        path: '/Permiss',
        name: 'Permiss',
        component: () => import('../views/permiss/Permiss')
    },
    //权限分配 - 新增管理员
    {
        path: '/addmiss',
        name: 'addmiss',
        component: () => import('../views/permiss/addmiss')
    },
    //群规则设置
    {
        path: '/ruleli',
        name: 'ruleli',
        component: () => import('../views/ruleli')
    },
    //群规则设置-1 惩罚设置
    {
        path: '/punish',
        name: 'punish',
        component: () => import('../views/ruleli/punish')
    },
    //群规则设置-2 指令设置
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/ruleli/order')
    },
    //群规则设置-3 自定义设置
    {
        path: '/custom',
        name: 'custom',
        component: () => import('../views/ruleli/custom')
    },
    //入群欢迎
    {
        path: '/group',
        name: 'group',
        component: () => import('../views/group')
    },
    //反机器人
    {
        path: '/robot',
        name: 'robot',
        component: () => import('../views/robot')
    },
    //禁止不良信息
    {
        path: '/badness',
        name: 'badness',
        component: () => import('../views/badness')
    },
    //刷屏设置
    {
        path: '/refresh',
        name: 'refresh',
        component: () => import('../views/refresh')
    },
    //入群审核
    {
        path: '/audit',
        name: 'audit',
        component: () => import('../views/audit')
    },
    //自动回复设置1
    {
        path: '/auto',
        name: 'auto',
        component: () => import('../views/auto')
    },
    //自动回复设置2
    {
        path: '/thekey',
        name: 'thekey',
        component: () => import('../views/auto/thekey')
    },
    //定时发送
    {
        path: '/timing',
        name: 'timing',
        component: () => import('../views/timing')
    },
    //定时发送
    {
        path: '/time',
        name: 'time',
        component: () => import('../views/timing/time')
    },
    {
        path: '/',
        name: 'index',
        component: () => import('../views/auto')
    },
    // 官方公告
    {
        path: '/annou',
        name: 'annou',
        component: () => import('../views/annou')
    },
];

const router = new VueRouter({
    mode: "history",
    // base: 'process.env.BASE_URL',
    routes,
});


Vue.use(VueRouter);
// const router = new VueRouter({ routes })
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    document.documentElement.scrollTop = 0
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
})


//   router.beforeEach((to, from, next) => {
//     if (to.matched.some((r) => r.meta.requireAuth)) {
//       if (store.state.isLogin) {
//         next();
//       } else {
//         next({
//           path: "/login",
//           query: { redirect: to.fullPath },
//         });
//       }
//     } else {
//       next();
//     }
//   });

export default router;




