let url
if (process.env.NODE_ENV == "development") {
    // 开发环境
    url = 'https://botapi.agzch.com/'//'http://192.168.50.41:8099/'
} else {
    // 生产环境
    url = 'https://botapi.agzch.com/'
}
export default {
    url
}