/**重置message，防止重复点击重复弹出message弹框 */
import { Message } from "element-ui";

let messageInstance = null;
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
["error", "success", "info", "warning"].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === "string") {
      options = {
        message: options,
      };
    } else {
      options = {
        message: options,
      };
    }

    options.type = type;
    return resetMessage(options);
  };
});

// Vue.prototype.$message = function(msg) {
//     ElementUI.Message({ ...msg, duration: 0 })
// }

export const message = resetMessage;
